<!-- 消息通知 -->
<template>
  <div>
    <div v-if="!addShow" :id="type == 5 ? 'boxwarn' : 'boxwarn'">
      <!-- <div class="yuandian"> </div> -->
      <div class="" :class="type == 5 ? 'top-warn' : 'top-warn'">
        <div class="left">
          <div class="icon">
            <img src="@/assets/zong_lan_ye_img/frame/icon.png" alt="" />
          </div>
          <div class="message-top">
            <span>消息通知</span>
            <!-- <span>({{ promptnumber.total }})</span> -->
          </div>
        </div>
        <div></div>
        <div class="top-centen">
          <div @click="() => $emit('handleClose')" class="closemessage cp">
            关闭
          </div>
        </div>
      </div>
      <div class="changeSearch">
        <!-- warnse -->
        <el-select
          :class="type == 5 ? '' : ''"
          v-model="type"
          clearable
          placeholder="消息类型"
          @change="Change"
        >
          <!-- :popper-append-to-body="false" -->
          <el-option
            class="option"
            :class="type == 5 ? 'list' : ''"
            v-for="item in typeList"
            :key="item.id"
            :label="item.dictionaryName"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <div
          @click="swicth"
          class="switchmessage ml8 cp"
          :class="{ swiach: isRead == 1 }"
        >
          <span>已读</span>
          <span>({{ promptnumber.read }})</span>
        </div>
        <div
          @click="isswicth"
          :class="{ swiach: isRead == 0 }"
          class="switchmessage ml4 cp"
        >
          <span>未读</span>
          <span>({{ promptnumber.notRead }})</span>
        </div>
        <div
          class="switchmessage swiach ml184 cp"
          v-if="roleId == 1 || roleId == 7"
          @click="addInfo()"
        >
          新建
        </div>
        <!-- <span
            v-if="roleId == 1 || roleId == 7"
            class="iconfont icon-xinjian mr15"
            style="font-size: 22px"
            :style="type == 5 ? 'color: #ed5944' : 'color: #00f5ff;'"
            @click="addInfo()"
          ></span> -->
      </div>
      <div :class="type == 5 ? 'gdtcentenwarn' : 'gdtcentenwarn'">
        <div
          class="message-centen"
          v-for="(item, index) in promptcenten"
          :key="index"
        >
          <div class="middlecenten">
            <div class="title">{{ item.newsTitle }}</div>
            <div class="centent">
              <span>{{ item.releaseName }}</span
              ><span class="ml15">{{ item.updatedTime }}</span>
            </div>
          </div>
          <div class="centenbreak mb15">{{ item.newsContent }}</div>
          <div class="statetype">
            <div class="sorting">
              <div
                @click="openfile(item2.fileUrl)"
                v-for="item2 in item.mmcNewsFileList"
                :key="item2.id"
                v-show="item2.fileUrl"
                class="attachment mr30"
              >
                <span class="iconfont icon-fujian mr5"></span>
                <span>{{ item2.fileName }}</span>
              </div>
            </div>
            <div
              @click="messageboxbtn(item)"
              v-show="isRead == 0"
              class="unread cp"
            >
              <span class="iconfont icon-weidu mr5"></span
              ><span class="mr5">未读</span>
            </div>
            <div v-show="isRead == 1" class="read cp">
              <span class="iconfont icon-duigouxiao mr5"></span
              ><span class="mr5">已读</span>
            </div>
          </div>
          <div class="linechange"></div>
        </div>
      </div>
      <!-- <div class="message-bottom">
        <div><span class="iconfont icon-weidu"></span><span>未读</span></div>
      </div> -->
    </div>
    <div v-else-if="addShow" :id="type == 5 ? 'boxwarn' : 'boxwarn'">
      <!-- <div class="yuandian"> </div> -->
      <div class="mb10 jubian" :class="type == 5 ? 'top-warn' : 'top-warn'">
        <div class="left">
          <div class="icon">
            <img src="@/assets/zong_lan_ye_img/frame/icon.png" alt="" />
          </div>
          <div class="message-top">
            <span>消息通知</span>
          </div>
        </div>
        <div></div>
        <div class="top-centen">
          <div @click="() => (addShow = false)" class="closemessage cp">
            返回
          </div>
        </div>
      </div>
      <div :class="type == 5 ? 'gdtcenten' : 'gdtcenten'">
        <div class="message-centen">
          <el-form :model="ruleForm" ref="ruleForm" label-width="120px">
            <el-form-item label="消息标题:" prop="newsTitle">
              <el-input
                v-model="ruleForm.newsTitle"
                clearable
                placeholder="请输入消息标题"
              ></el-input>
            </el-form-item>
            <el-form-item label="消息类型:" prop="newsType">
              <el-select
                class="selectIcon"
                v-model="ruleForm.newsType"
                clearable
                placeholder="请选择消息类型"
              >
                <el-option
                  class="option"
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.dictionaryName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="消息内容:" prop="newsContent">
              <el-input
                v-model="ruleForm.newsContent"
                clearable
                type="textarea"
                placeholder="请输入消息内容"
              ></el-input>
            </el-form-item>
            <el-form-item label="附件名称:" prop="mmcNewsFileList">
              <div class="files" v-for="(item, index) in options" :key="index">
                <el-input v-model="item.file"></el-input>
                <div class="text">
                  <el-upload
                    v-if="options.length - 1 === index || options.length == 0"
                    class="upload ml10"
                    :action="url"
                    :multiple="false"
                    :show-file-list="false"
                    accept="*"
                    :file-list="fileList"
                    :on-change="onChange"
                    :on-remove="onRemove"
                    :on-success="changeFile"
                  >
                    <span class="iconfont icon-fujian c08f"></span>
                  </el-upload>
                  <span
                    class="iconfont icon-tianjia1 ml10 c08f"
                    v-if="options.length - 1 === index || options.length == 0"
                    @click="add"
                  ></span>
                  <span
                    class="iconfont icon-shanchu3 ml10 c08f"
                    @click="deleteData(index)"
                  ></span>
                </div>
              </div>
            </el-form-item>
          </el-form>
          <div style="display: flex; justify-content: center" class="mt40">
            <div class="submit" @click="submitForm">提交</div>
          </div>
          <!-- <el-button class="submit" type="primary" @click="submitForm"
              >提交</el-button
            > -->
        </div>
      </div>
      <!-- <div class="message-bottom">
        <div><span class="iconfont icon-weidu"></span><span>未读</span></div>
      </div> -->
    </div>
  </div>
</template>
  <script>
import API from "@/api";
export default {
  name: "Message",
  data() {
    return {
      cengji: null, //接收vuex传来的值,解决层级问题
      typeList: [],
      roleId: JSON.parse(localStorage.getItem("user_info")).data.roles[0]
        .roleId,
      ruleForm: {
        mmcNewsFileList: [
          {
            fileName: "",
            fileUrl: "",
            fileUrlName: "",
          },
        ],
      },
      url: "",
      type: null,
      promptnumber: {
        total: 0,
        read: 0,
        notRead: 0,
      },
      addShow: false,
      speed: 1,
      isRead: 0,
      promptcenten: null,
      newsId: null,
      timeMessagenumber: null,
      timeMessagecenten: null,
      fileList: [],
      options: [
        {
          file: null,
          fileName: null,
          fileUrl: null,
          fileUrlName: null,
        },
      ],
    };
  },
  mounted() {
    let baseUrl = process.env.VUE_APP_BASE_URL;
    this.url = baseUrl + "/upload/v2_uploads";
    this.messagenumber();
    this.messagecenten(null);
    this.getmessagetype();
    // this.timeMessagenumber = setInterval(this.messagenumber, 2000);
    this.timeMessagecenten = setInterval(this.messagecenten(this.type), 2000);
    //vuex层级问题(关键:层级问题)
    this.cengji = this.$store.state.cengji.Zindex;
    // console.log(this.cengji, "查看层级问题");
  },
  methods: {
    addInfo() {
      this.addShow = true;
    },
    openfile(url) {
      window.open(url);
    },
    onRemove() {
      this.ruleForm.url = null;
    },
    onChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]]; //这一步，是 展示最后一次选择文件
      }
    },
    async changeFile(response, file) {
      let fd = new FormData();
      fd.append("uploadFiles", file.raw);
      let res = await API.MAPMANAGE.fileUpload(fd);
      // if (res.status == 1) {
      this.options[this.options.length - 1].fileName = res[0].name;
      this.options[this.options.length - 1].file = res[0].name;
      this.options[this.options.length - 1].fileUrl = res[0].url;
      // } else {
      //   this.$message.error(res?.message);
      // }
    },
    // 增加
    add() {
      this.options.push({
        fileName: "",
        fileUrl: "",
        file: "",
      });
      this.ruleForm.mmcNewsFileList.push({
        fileName: "",
        fileUrl: "",
        fileUrlName: "",
      });
      // this.onchangeSelect();
    },
    // 删除
    deleteData(index) {
      if (this.options.length - 1 < 1) {
        this.$alert("至少要有一个附件");
        return;
      } else {
        this.options.splice(index, 1);
        this.ruleForm.mmcNewsFileList.splice(index, 1);
      }
    },
    Change() {
      this.messagecenten(this.type);
    },
    submitForm() {
      if (this.options.length == 1 && this.options[0].file == null) {
        this.ruleForm.mmcNewsFileList = null;
      } else {
        this.options.forEach((val, index) => {
          if (val.file != null || val.file != "") {
            this.ruleForm.mmcNewsFileList[index].fileName = val.file;
            this.ruleForm.mmcNewsFileList[index].fileUrl = val.fileUrl;
            this.ruleForm.mmcNewsFileList[index].fileUrlName = val.fileName;
          }
        });
      }
      this.ruleForm.isRelease = 1;
      API.HOME.addnews(this.ruleForm).then((res) => {
        if (res == "创建成功") {
          this.$message.success(res);
          this.addShow = false;
          this.messagenumber();
          this.messagecenten(this.type);
        } else {
          this.$message.error(res);
        }
        this.ruleForm = {
          mmcNewsFileList: [
            {
              fileName: "",
              fileUrl: "",
              fileUrlName: "",
            },
          ],
        };
      });
    },
    async getmessagetype() {
      let res = await API.HOME.getmessagetype();
      this.typeList = res;
    },
    async messageboxbtn(item) {
      if (item.id) {
        await API.HOME.readingmessage({ newsId: item.id });
      } else {
        await API.HOME.readingmessage({ waringId: item.waringId });
      }
      this.isswicth();
      this.messagenumber();
    },
    async messagenumber() {
      let res = await API.HOME.getmessagenumber();
      this.promptnumber = res;
      this.$emit("numbeichange", this.promptnumber.notRead);
    },
    async messagecenten(type) {
      if (type) {
        console.log(type, "123");
        var res = await API.HOME.getmessagecontent({
          isRead: this.isRead,
          newsId: this.newsId,
          type: type,
        });
      } else {
        var res = await API.HOME.getmessagecontent({
          isRead: this.isRead,
          newsId: this.newsId,
        });
      }
      this.promptcenten = res;
    },
    async swicth() {
      this.isRead = 1;
      this.messagecenten(this.type);
    },
    async isswicth() {
      this.isRead = 0;
      this.messagecenten(this.type);
    },
  },
  beforeDestroy() {
    clearInterval(this.timeMessagecenten);
  },
  watch: {
    messagenum: {
      handler(val) {
        if (val.includes((num) => num == 5)) {
          this.messagenumber();
        }
      },
    },
  },
};
</script>
  <style>
::v-deep .el-input__inner {
  background: #161d2d;
}
</style>
  <style scoped lang="scss">
.z_index {
  z-index: 99 !important;
}

#box {
  width: 600px;
  height: 400px;
  background: rgba(47, 33, 33, 0.8);
  // border: 1px solid #979797;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  position: fixed;
  z-index: 10;
  top: 200px;
  // right: 600px;
  right: 50%;
  margin-right: -365px;
  .top-heafer {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    background: rgba(140, 56, 0, 0.2);
    box-shadow: inset 0 0 22px 0 rgba(255, 32, 0, 0.5);
    border-radius: 10px 10px 0 0;
    display: flex;
    align-items: center;
    padding: 0 0 0 24px;
    justify-content: space-between;
    .left {
      display: flex;
      justify-content: center;
      .message-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: MicrosoftYaHei-Bold;
        font-size: 18px;
        // color: #00ffff;
        background-image: -webkit-linear-gradient(
          right,
          #e3aa77,
          #f5cda9,
          #f9ecd3,
          #fcdbb1,
          #edb07a
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        letter-spacing: 0;
        font-weight: 700;
        margin-left: 4px;
      }
    }

    .top-centen {
      display: flex;
      align-items: center;
      margin: 0 0 0 35px;
      padding: 6px 0 0 0;
      height: 100%;

      .switchmessage {
        background: rgba(36, 0, 0, 0.4);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5),
          inset 0 0 15px 0 rgba(253, 77, 77, 0.7);
        border-radius: 6px;
        width: 120px;
        height: 27px;
        margin-top: -5px;
        line-height: 27px;
        text-align: center;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #ffa7a7;
        letter-spacing: 0;
        font-weight: 400;
      }

      .swiach {
        background: rgba(140, 0, 0, 0.7);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 20px 0 #fd4d4d;
        border-radius: 6px;
      }

      .closemessage {
        font-family: MicrosoftYaHei;
        font-size: 18px;
        color: #ed5944;
        letter-spacing: 0;
        font-weight: 400;
        margin: 0 35px 6px 0;
      }
    }
  }

  .gdtcenten {
    overflow-y: auto;
    width: 100%;
    height: 400px;

    .message-centen {
      width: 100%;
      // height: 400px;
      padding: 20px 25px 30px 35px;
      box-sizing: border-box;

      // margin-bottom: 55px;
      .middlecenten {
        display: flex;
        width: 100%;
        height: 100%;
        // padding: 30px 25px 30px 25px;
        justify-content: space-between;

        // box-sizing: border-box;
        .title {
          width: 103px;
          height: 28px;
          background: #ed5944;
          border: 1px solid #ed5944;
          text-align: center;
          line-height: 28px;
          font-family: MicrosoftYaHei-Bold;
          font-size: 14px;
          color: #ffffff;
          letter-spacing: 0;
          font-weight: 700;
        }

        .centent {
          font-family: MicrosoftYaHei-Bold;
          font-size: 16px;
          color: #ed5944;
          letter-spacing: 0;
          font-weight: 700;
        }
      }

      .centenbreak {
        background: url("~@/assets/images/warn/bg.png");
        width: 643px;
        height: 98px;
        text-align: left;
        // border: 1px solid #0083d3;
        padding: 10px 7px;
        box-sizing: border-box;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #f6d3ce;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: 400;
        text-indent: 28px;
        overflow: auto;
      }

      .statetype {
        display: flex;
        justify-content: space-between;

        .sorting {
          display: flex;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #00e0ff;
          letter-spacing: 0;
          font-weight: 400;
          cursor: pointer;
        }

        .unread {
          display: flex;
          justify-content: flex-end;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: rgba(237, 89, 68, 0.48);
          letter-spacing: 0;
          font-weight: 400;
        }

        .read {
          display: flex;
          justify-content: flex-end;
          // background-color: #0083d3 !important;
          color: #ed5944;
        }
      }

      .linechange {
        margin-top: 20px;
        height: 2px;
        width: 100%;
        background: linear-gradient(
          244deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(237, 89, 68, 1) 50%,
          rgba(255, 255, 255, 0) 100%
        ) !important;
      }
    }
  }
}

#boxwarn {
  width: 600px;
  height: 400px;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  // backdrop-filter: blur(1px);
  position: fixed;
  z-index: 10;
  top: 200px;
  // right: 600px;
  right: 50%;
  margin-right: -365px;

  .top-warn {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;
    display: flex;
    align-items: center;
    padding: 0 24px;
    justify-content: space-between;
    .left {
      display: flex;
      justify-content: center;
      .message-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: MicrosoftYaHei-Bold;
        font-size: 18px;
        // color: #00ffff;
        background-image: -webkit-linear-gradient(
          right,
          #e3aa77,
          #f5cda9,
          #f9ecd3,
          #fcdbb1,
          #edb07a
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        letter-spacing: 0;
        font-weight: 700;
        margin-left: 4px;
      }
    }

    .top-centen {
      display: flex;
      align-items: center;
      margin: 0 0 0 35px;
      padding: 6px 0 0 0;

      .swiach {
        // background: rgba(0, 34, 140, 0.3) !important;
        background: url("~@/assets/zong_lan_ye_img/frame/messbg.png");
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 10px 0 #00b6ff !important;
        border-radius: 6px;
        opacity: 1;
      }

      .switchmessage {
        background: rgba(0, 3, 36, 0.4);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5),
          inset 0 0 15px 0 rgba(0, 182, 255, 0.7);
        border-radius: 6px;
        width: 120px;
        height: 27px;
        margin-top: -5px;
        line-height: 27px;
        text-align: center;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: rgba(0, 255, 255, 0.7);
        letter-spacing: 0;
        font-weight: 400;
      }

      .closemessage {
        font-size: 18px;
        font-family: MicrosoftYaHei;
        color: #d2dfff;
        letter-spacing: 0;
        font-weight: 400;
        margin: 0 0 4px 0;
      }
    }
  }

  .gdtcentenwarn {
    overflow-y: auto;
    width: 100%;
    height: 300px;

    .message-centen {
      width: 100%;
      // height: 400px;
      padding: 20px 25px 20px 35px;
      box-sizing: border-box;
      .el-form-item {
        margin-bottom: 15px;
      }

      // margin-bottom: 55px;
      .middlecenten {
        display: flex;
        width: 100%;
        height: 100%;
        // padding: 30px 25px 30px 25px;
        justify-content: space-between;

        // box-sizing: border-box;
        .title {
          width: 103px;
          height: 28px;
          background: #0083d3;
          border: 1px solid #0083d3;
          text-align: center;
          line-height: 28px;
          font-family: MicrosoftYaHei-Bold;
          font-size: 14px;
          color: #000f17;
          letter-spacing: 0;
          font-weight: 700;
        }

        .centent {
          font-family: MicrosoftYaHei-Bold;
          font-size: 16px;
          color: #92d9ff;
          letter-spacing: 0;
          font-weight: 700;
        }
      }

      .centenbreak {
        width: 530px;
        height: 98px;
        text-align: left;
        border: 1px solid #0083d3;
        padding: 10px 7px;
        box-sizing: border-box;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #92d9ff;
        letter-spacing: 0;
        line-height: 22px;
        font-weight: 400;
        text-indent: 28px;
        overflow: auto;
      }

      .statetype {
        display: flex;
        justify-content: space-between;

        .sorting {
          display: flex;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #00e0ff;
          letter-spacing: 0;
          font-weight: 400;
          cursor: pointer;
        }

        .unread {
          display: flex;
          justify-content: flex-end;
          color: rgb(44, 199, 220);
        }

        .read {
          display: flex;
          justify-content: flex-end;
          // background-color: #0083d3 !important;
          color: #0083d3;
        }
      }

      .linechange {
        margin-top: 20px;
        height: 2px;
        width: 100%;
        background: linear-gradient(
          244deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(59, 126, 194, 1) 50%,
          rgba(255, 255, 255, 0) 100%
        ) !important;
      }
    }
  }
}
.changeSearch {
  margin-top: 12px;
  display: flex;
  .switchmessage {
    width: 72px;
    height: 32px;
    background: #02173d;
    box-shadow: inset 0px 0px 6px 2px rgba(38, 71, 238, 0.7);
    border-radius: 2px;
    border: 1px solid #315ec7;
    line-height: 32px;
    text-align: center;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #d2d9ff;
    letter-spacing: 0;
    font-weight: 400;
  }
  .swiach {
    // background: rgba(0, 34, 140, 0.3) !important;
    background: url("~@/assets/zong_lan_ye_img/frame/messbg.png");
    background-size: 100%;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 10px 0 #00b6ff !important;
    // border-radius: 6px;
    // opacity: 1;
  }
}

body #box ::-webkit-scrollbar {
  width: 7px;
  background-color: #333c57;
  height: 9px;
}

body #box ::-webkit-scrollbar-thumb {
  background: #ed5944 !important;
  border-radius: 8px;
}

.el-select {
  // width: 150px;
  margin-left: 12px;
  width: 160px;
  height: 32px;
  background: #02173d;
  border-radius: 2px;
  // height: 27px;

}



/deep/ .el-select .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid #2b56b7;
  // box-shadow: inset 0 0 3px 0 #00B6FF;
  width: 160px;
  height: 32px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #00b6ff;
  letter-spacing: 0;
  font-weight: 400;
  border-radius: 4px;
  &:hover{
    border: 1px solid #2b56b7 !important;
  }
  // &::-webkit-input-placeholder {
  //   color: rgba(0, 182, 255, 0.7);
  // }
}

/deep/ .el-select .el-input__inner ::-webkit-input-placeholder {
  color: rgba(0, 182, 255, 0.7);
}

/deep/.el-form {
  .el-form-item__content {
    width: 150px;

    .el-input,
    .el-select {
      width: 230px;
      margin-left: 0;
      border-radius: 5px;
      margin-top: -4px;

      .el-input__inner,
      .el-textarea__inner {
        width: 230px;
        background: rgba(11, 23, 49, 0.8);
      }
    }

    .el-textarea {
      width: 230px;
      margin-left: -3px !important;
    }
  }
}

/deep/ .warnse .el-input__inner {
  background: rgba(39, 4, 3, 0.8);
  border: 1px solid #ed5944;
  // box-shadow: inset 0 0 3px 0 #00B6FF;
  width: 150px;
  height: 32px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #ed5944;
  letter-spacing: 0;
  font-weight: 400;
}

/deep/.warnse .el-input.is-focus .el-input__inner {
  border-color: #ed5944;
}

::v-deep .warnse .el-select-dropdown {
  .el-scrollbar {
    background: rgba(39, 4, 3, 0.8) !important;
    border: 1px solid #ed5944 !important;
    box-shadow: inset 0 0 3px 0 #00b6ff;
    border-radius: 0 0 6px 6px;

    .option {
      font-family: MicrosoftYaHei;
      font-size: 14px !important;
      color: #ed5944 !important;
      letter-spacing: 0;
      font-weight: 400;
    }

    .list.hover,
    .list:hover {
      background-image: linear-gradient(
        90deg,
        rgba(167, 101, 40, 0.7) 0%,
        rgba(155, 90, 30, 0.37) 51%,
        rgba(178, 119, 36, 0.37) 100%
      ) !important;
      font-family: MicrosoftYaHei;
      font-size: 14px !important;
      color: #fdb04d !important;
    }
  }
}

::v-deep .el-select .el-select-dropdown {
  .el-scrollbar {
    // background: rgba(39,4,3,0.80) !important;
    // border: 1px solid #ED5944 !important;
    // box-shadow: inset 0 0 3px 0 #00B6FF;
    border-radius: 0 0 6px 6px;

    .option {
      font-family: MicrosoftYaHei;
      font-size: 14px !important;
      // color: #ED5944 !important;
      letter-spacing: 0;
      font-weight: 400;
    }

    .list.hover,
    .list:hover {
      background-image: linear-gradient(
        90deg,
        rgba(167, 101, 40, 0.7) 0%,
        rgba(155, 90, 30, 0.37) 51%,
        rgba(178, 119, 36, 0.37) 100%
      ) !important;
      font-family: MicrosoftYaHei;
      font-size: 14px !important;
      // color: #FDB04D !important;
    }
  }
}

.el-popper .popper__arrow::after {
  background: rgba(39, 4, 3, 0.8) !important;
  border: 1px solid #ed5944 !important;
}

.files {
  display: flex;

  .el-input {
    .el-input__inner {
      width: 200px;
    }
  }

  .text {
    display: flex;
  }
}

.c08f {
  color: #08ffff;
}

.submit {
  width: 90px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  background: rgba(0, 34, 140, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 20px 0 #00b6ff;
  border-radius: 6px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #00ffff;
  letter-spacing: 0;
  font-weight: 400;
}

/deep/ .el-select .el-input .el-select__caret {
  color: rgba(0, 182, 255, 0.7);
  // margin-top: 2px;
}

.selectIcon{
  /deep/.el-input__suffix{
    margin-top: -2px !important;
  }
  /deep/ .is-reverse{
    margin-top: 3px !important;
  }
}

.jubian {
  justify-content: space-between;
  padding: 0 35px 0 0;
}
/deep/ .el-form-item__label {
  color: #fff !important;
}
</style>
  